import { FC } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

const GooglePlayButtonComponent: FC = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Link href="https://play.google.com/store/apps/details?id=io.ecbo.cloak">
      <img src="/google_play.webp" alt={t('META.GOOGLE_PLAY')} />
    </Link>
  )
}

export default GooglePlayButtonComponent
