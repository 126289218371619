import { Lang } from 'types/lang'

/**
 * 言語一覧を取得する
 * @returns Lang[] 言語一覧
 */
export function getLangs(): Lang[] {
  return [
    {
      slug: 'ja',
      name: '日本語',
    },
    {
      slug: 'en',
      name: 'English',
    },
    {
      slug: 'zh-TW',
      name: '繁体字',
    },
  ]
}

/**
 * 選択した言語キーを条件に言語データを取得する
 * @param locale string 選択した言語のキー
 * @returns Lang 選択した言語のデータ
 */
export function getLangByLocale(locale: string): Lang {
  const langs: Lang[] = getLangs()

  let lang: Lang = langs[0]
  langs.map((l: Lang) => {
    if (l.slug === locale) {
      lang = l
    }
  })

  return lang
}

/**
 * localeを置換する
 * @param locale
 * @returns
 */
export function replaceLocale(locale: string): string {
  return locale.replace('zh_tw', 'zh-TW')
}
