import { useCallback, useContext, useState } from 'react'
import Router from 'next/router'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { destroyCookie } from 'nookies'
import { signOut } from 'next-auth/client'
import { useRouter } from 'next/router'

import { AuthContext } from 'providers/AuthProvider'

//type
import { Lang } from 'types/lang'

//hooks
import { getLangs, getLangByLocale } from 'hooks/common/lang'
import { useSpaceRemoveSearchParams } from 'hooks/space/remove_search_params'

import styles from './HeaderMenuComponent.module.scss'

const HeaderMenuComponent = () => {
  const { isSigned } = useContext(AuthContext)
  const { t } = useTranslation()

  const { asPath, locale } = useRouter()

  const [isShowLang, setIsShowLang] = useState<boolean>(false)

  const langs: Lang[] = getLangs()

  const _onPressLogout = useCallback((): void => {
    destroyCookie(null, 'jwt_token', { path: '/' })
    destroyCookie(null, 'user_id', { path: '/' })
    // @ts-ignore
    signOut({ redirect: false, callbackUrl: '/' })
    useSpaceRemoveSearchParams()
    Router.push('/')
  }, [])

  return (
    <ul className={styles.menu}>
      {!isSigned ? (
        <>
          <li>
            <Link href="/users/sign_up">{t('COMMON.HEADER.REGISTER')}</Link>
          </li>
          <li>
            <Link href="/users/sign_in">{t('COMMON.HEADER.LOGIN')}</Link>
          </li>
          <li>
            <Link href={`${process.env.AUTH_API_URL}/owners/condition_confirmation`}>
              {t('COMMON.HEADER.OWNER.REGISTER')}
            </Link>
          </li>
        </>
      ) : (
        <li className={styles.logoutButton} onClick={_onPressLogout}>
          {t('COMMON.HEADER.LOGOUT')}
        </li>
      )}
      <li className={styles.switchLang}>
        <dl className={styles.switchLangBlock}>
          <dt className={styles.switchLangButton} onClick={() => setIsShowLang(!isShowLang)}>
            <img
              className={styles.switchLangButtonIcon}
              src="/language_icon.svg"
              alt={t('META.LANGUAGE_ICON')}
            />
            <strong className={styles.switchLangButtonText}>{getLangByLocale(locale).name}</strong>
            <img src="/triangle_bottom_icon.svg" alt={t('META.LANGUAGE_ICON')} />
          </dt>
          {isShowLang && (
            <dd className={styles.switchLangBox}>
              <ul className={styles.switchLangList}>
                {langs.map((lang: Lang, index: number) => (
                  <li
                    className={`${styles.switchLangListItem} ${
                      lang.slug === locale && styles.switchLangListItemSelected
                    }`}
                    key={index}
                  >
                    <Link href={asPath} locale={lang.slug} passHref>
                      <a className={styles.switchLangLink}>{lang.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </dd>
          )}
        </dl>
      </li>
    </ul>
  )
}

export default HeaderMenuComponent
